.sidebar {

	h2 {
		font-size: 24px;
	}

	section.widget {
		border-bottom: 1px solid #eee;
		padding-bottom: 15px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				padding: 10px 0;
				border-top: 1px solid #eee;

				a, span {
					display: block;
				}
			}
		}

		&.widget_search {
			@extend .clearfix;

			label {
				width: calc(100% - 80px);
				display: inline-block;
				float: left;
			}

			input[type="search"] {
				line-height: 20px;
				padding: 25px 12.5px;
				border-radius: 0;
				width: calc(100%);
				display: inline-block;
				border-color: $primary;
			}

			input[type="submit"] {
				@extend .btn;
				@extend .btn-primary;
				width: 80px;
				text-align: center;
				padding: 15px 0;
				display: inline-block;
				float: left;
			}
		}
	}
}