* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	line-height: 1.5em;
	overflow-x: hidden;
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	letter-spacing: 1px;
	font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 20px;
	line-height: 1.5em;
	font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;

	&.underline {
		position: relative;
		text-align: center;
		padding-bottom: 20px;

		&:after {
			position: absolute;
			content: '';
			bottom: 0px;
			left: 50%;
			height: 2px;
			width: 100px;
			margin-left: -50px;
			background: $primary;
		}
	}
}

h1 {
	margin: 0 0 20px;
}

h2 {
	font-size: 30px;
    line-height: 40px;
}

a {
	color: $primary;
	text-decoration: none;
}

p {
	margin-bottom: 1.5em;
}

.center {
	text-align: center;
}

.single-remodeling .page-header {
	margin: 0;
	border: 0;
	padding: 23px 0 0;
	h1 {
		font-size: 16px;
	}
}

.mobile-trigger {
	display: none;

	@media #{$mobile} {
		line-height: 30px;
		padding: 10px 0;
		font-size: 24px;
		color: #fff;
		background-color: $primary;
		display: block;
		text-align: center;

		&:focus,
		&:active,
		&:hover {
			color: #fff;
		}
	}
}

.mobile {
	display: none;
	background-color: darken($primary, 2%);

	@media #{$mobile} {
		display: block;

		.navbar-collapse.collapse {
			display: none !important;

			&.in {
				display: block !important;
			}
		}
		ul {
			list-style: none;
			padding: 0;
			text-align: center;
			display: block;
			float: none;

			li {
				float: none;
				float: none;
			}

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

img {
	max-width: 100%;
	height: auto;
}
.text-center {
	text-align: center;
}

.mt15 {
	margin-top: 15px;
}

.mt30 {
	margin-top: 30px;
}

.mt60 {
	margin-top: 60px;
}

.mt90 {
	margin-top: 90px;
}

.mt120 {
	margin-top: 120px;
}

.mt150 {
	margin-top: 150px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb60 {
	margin-bottom: 60px;
}

.mb90 {
	margin-bottom: 90px;
}

.mb120 {
	margin-bottom: 120px;
}

.mb150 {
	margin-bottom: 150px;
}

hr {
	border-width: 5px;
	border-color: $primary;
	width: 150px;
}

.free-consulation-bar {
	display: block;
	text-align: center;
	font-size: 35px;
	line-height: 40px;
	padding: 30px 0;
	background: $primary;

	&:hover {
		background: darken($primary, 5%);
		text-decoration: none !important;
	}

	a, h2 {
		color: #fff;
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			text-decoration: none !important;
		}
	}
}