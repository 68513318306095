.carousel {
	line-height: 100px;
	background: #eee;
	text-align: center;
	font-family: "Lora", serif;
	font-style: italic;
	font-size: 22px;

	h1 {
		font-family: "Lora", serif;
		font-style: italic;
		font-size: 22px;
		margin: 0;
		padding: 0;
		line-height: 100px;
		font-weight: 400;

		@media #{$mobile} {
			line-height: 1.5em;
			padding: 15px;
		}
	}
}

.header-video {
	background: #313131;
	color: #fff;
	padding: 30px 0;
	margin-bottom: 30px;
	margin-top: -1px;

	strong {
		color: #fff;
		display: inline-block;
		margin-bottom: 30px;
		font-family: "Lora", serif;
		font-size: 20px;
	}
}

.featured-kitchens {
	padding: 60px 0;
	text-align: center;
	background: #f7f7f7;
	margin-top: 40px;

	.btn {
		margin-top: 30px;
	}
}

.image-container {
	padding-top: 56%;
	background-size: cover;
	background-position: center center;
	margin-bottom: 30px;
}

.menu-item-74 {

	@media #{$mobile} {
		display: none;
	}
}

.video-content {
	p {
		margin: 0;
	}
}

.home .item {
	height: calc(70vh - 90px);
	min-height: 500px;
	background: #eee;
	background-size: cover;
	background-position: center center;
	font-family: "Raleway", sans-serif;
	font-style: normal;
	color: #fff;

	@media #{$tablet} {
		height: 80vw;
		min-height: auto;
	}

	h2 {
		font-family: "Raleway", sans-serif;
		font-style: normal;
		color: #fff;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 65px;
		line-height: 1em;
		margin: 0;
		text-shadow: 0 2px 10px rgba(0,0,0,0.5);

		@media #{$tablet} {
			font-size: 9vw;
		}
	}

	.content {
		font-size: 16px;
		text-shadow: 0 2px 2px rgba(0,0,0,0.5);
		font-weight: 700;
		line-height: 1.5em;
	}

	table {
		height: calc(70vh - 90px);
		min-height: 500px;
		width: 100%;

		@media #{$tablet} {
			height: 80vw;
			min-height: auto;
		}

		td {
			vertical-align: middle;
		}
	}
}

.page-hero {
	height: calc(70vh - 90px);
	min-height: 500px;
	background-color: #eee;
	background-size: cover;
	background-position: center center;
	position: relative;
	color: #fff;
	line-height: 1.5em;

	@media #{$mobile} {
		height: auto;
		min-height: 1px;
	}

	.city-container {
		text-align: center;

		h2 {
			text-shadow: 2px 2px 0 #555;
		}
	}

	.col-sm-8 {
		padding: 30px 0;
	}

	table {
		height: calc(70vh - 90px);
		min-height: 500px;
		width: 100%;

		@media #{$mobile} {
			height: auto;
			min-height: 1px;
			margin: 15px 0;
		}

		td {
			vertical-align: middle;
		}
	}

	.overlay {
		background: #f4f4f4;
		color: #222;
		padding: 30px 40px 60px;
		position: relative;

		@media #{$mobile} {
			background: rgba(#f4f4f4, 0.8);
			padding: 30px 15px 60px;
		}


		h1, h2, h3 {
			margin-top: 0;
			color: #222;
		}

		h2 {
			font-size: 40px;
			line-height: 50px;
			font-weight: 400;
		}

		&:after {
			left: 40px;
			bottom: 40px;
			position: absolute;
			height: 5px;
			width: 150px;
			content: '';
			background: $primary;
		}
	}
}

.page-id-60 {

	.page-hero {
		height: auto;

		p {
			font-size: 30px;
			line-height: 1.5em;
		}

		input,
		select {
			color: #000;
			padding: 12px;
			-webkit-appearance: none;
		}
	}
}

.our-process {
	padding: 60px 0;
	text-align: center;

	h2 {
		margin-top: 0;
		margin-bottom: 60px;
	}

	.image {
		margin-bottom: 30px;

		img {
			height: 140px;
			width: auto;
		}
	}

	.title {
		margin-bottom: 30px;

		h2 {
			margin-bottom: 23px;
		}
	}

	.description {

	}

	.btn {
		margin-top: 60px;
	}
}

.features-services {
	background: #f4f4f4;
	padding: 60px 0;

	@media #{$tablet} {
		padding: 30px 0;
	}

	table {
		height: 660px;

		@media #{$tablet} {
			height: auto;
			margin-bottom: 30px;
		}

		td {
			vertical-align: middle;
		}
	}

	article {

		header {
			height: 200px;
			background-size: cover;
			background-position: center center;
			position: relative;
			margin-bottom: 30px;

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				line-height: 100%;
				text-align: center;
				background: rgba($primary, 0.2);

				&:hover {
					background: rgba($primary, 0);
				}

				h1 {
					margin: 0;
					line-height: 200px;
					color: #fff;
					padding: 0 15px;
					font-size: 22px;
					text-shadow: 0 2px 10px rgba(0,0,0,0.5);
				}
			}
		}
	}
}

.page-id-54 {

	.cert-logos {
		background: #f7f7f7;
		padding: 30px 0;

		ul {
			margin: 0;
		}

		li {
			width: 30%;

			@media #{$mobile} {
				width: 100%;
			}
		}

		img {
			max-height: 60px;
			margin: 0 30px;

			@media #{$mobile} {
				height: auto;
				max-height: 9999px;
				width: 100%;
				margin: 15px 0;
			}
		}
	}
}

.showroom-newsletter {
	position: relative;
	padding: 60px 0;
	color: #000;
	text-align: center;
	background-size: cover;
	background-position: center center;

	.btn {
		margin-top: 23px;
	}

	#mc_embed_signup form {
		margin: 0;
	}

	#mc_embed_signup_scroll {
		text-align: center;

		.mc-field-group {

			ul {

				li {
					display: inline-block !important;

					input {
						margin-right: 5px !important;
					}
				}
			}
		}
	}

	label {
		margin: 0 !important;
	}

	input[type="email"] {
		border: 0 !important;
		padding: 10px !important;
	}

	input[type="submit"] {
		border: 0 !important;
		padding: 10px 30px !important;
		border-radius: 0;
		background: #000 !important;
		color: #fff !important;
		margin-top: 23px !important;
		height: auto !important;
	}
}

.remodeling-container {
	text-align: center;

	@media #{$mobile} {
		text-align: left;

		.btn {
			font-size: 14px;
			padding: 10px;
			margin-bottom: 30px;
		}

		.post-thumbnail {
			margin-bottom: 15px;
		}
	}

	&.odd {
		background: #eee;
	}

	.entry-content {
		margin-bottom: 23px;
		padding: 0 50px;

		@media #{$mobile} {
			padding: 0;
		}
	}

	img {
		height: 550px;
		width: auto;

		@media #{$desktop}{
			height: 380px;
		}

		@media #{$tablet}{
			height: auto;
			width: 100%;
		}
	}

	table {
		width: 100%;
		height: 550px;

		@media #{$desktop}{
			height: 350px;
		}

		td {
			vertical-align: middle;
		}
	}
}

.entry-content {
	img {
		width: 100%;
	}
}

.consultation-container {


	.page-header {
		margin: 0;
		padding: 0;
		border: 0;

		h1 {
			margin: 30px 0 0;
			font-size: 16px;
		}
	}
}

.gallery-container {
	margin-top: 60px;
	text-align: center;

	@media #{$mobile} {
		margin-top: 30px;

		.container-fluid {
			padding: 0;
		}
	}
}

.envira-lazy {
	position: relative;
	overflow: hidden;

	&:hover {
		img {
			transform: scale(1.05);
		}
		&:after {
			opacity: 0.2;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,1);
		opacity: 0;
	}
}

.about-us-container {

	@media #{$mobile} {
		text-align: left !important;

		* {
			text-align: left !important;
		}
	}

	h3 {
		margin: 30px 0;
	}

	.content {
		padding: 0 30px;
		line-height: 1.5em;

		@media #{$mobile} {
			padding: 0;
		}
	}
}

.about-us-image {
	height: 500px;
	background-size: cover;
	background-position: center center;

	@media #{$mobile} {
		height: 120vw;
	}
}

.blog-content-container {
	margin-top: 10px;
	background: #f8f8f8;
	padding: 15px;

	@media #{$mobile} {
		img {
			margin-bottom: 23px;
		}
	}

	time {
		font-size: 12px;
		text-transform: uppercase;
	}

	h1 {
		font-size: 20px;
		margin-bottom: 0;
		line-height: 1.5em;
		height: 1.5em;
		overflow: hidden;
		margin-top: -4px;
	}

	.blog-content {
		margin: 15px 0 23px;
		line-height: 1.5em;
		max-height: 3em;
		overflow: hidden;
	}

	.btn {
		padding: 5px 10px;
		font-size: 12px;
		text-transform: uppercase;
		float: right;
	}
}

.work-with-us-container {
	margin-bottom: 33px;
	margin-top: 27px;
}

.idea-box {
	background: #f8f8f8;
	padding: 30px;
	margin-top: 33px;
	border-top: 1px solid #eee;

	p {
		margin: 0;
	}
}

.list-container {
	b {
		display: block;
		font-size: 20px;
		margin-bottom: 33px;
	}

	i {

	}

	li {
		line-height: 1.5em;
		padding: 10px 0;
	}
}

.single-post-container {
	margin-top: 30px;
}

.cert-logos {
	text-align: center;
	padding: 15px 0;
	li {
		width: calc(100% / 5);
		margin-left: -4px;
		
		@media #{$tablet} {
			width: calc(100% / 3);
		}
		
		@media #{$mobile} {
			width: calc(100% / 1);
		}
	}
}

.single-team-container {
	margin-bottom: 30px;

	header {
		.post-thumbnail {
			height: 450px;
			background-position: center center;
			background-size: cover;
			border: 10px solid #f8f8f8;
		}
	}

	main {

		h2 {
			text-align: left;
			font-weight: 400;
			color: #333;
		}

		h4.underline {
			text-align: left;
			font-weight: 400;


			&:after {
				left: 0;
				margin-left: 0;
			}
		}

		.the-content {
			line-height: 1.5em;
			height: 3em;
			overflow: hidden;
			margin-bottom: 20px;
		}
	}
}

.warranty-container {
	padding: 45px 0;
	background-size: cover;
	background-position: center center;
}

.awards-container {
	background: #f7f7f7;
	padding: 60px 0;

	@media #{$mobile} {
		padding: 30px 0;
	}
}

.award-container {
	text-align: center;

	@media #{$mobile} {
		margin-bottom: 15px;
	}

	.image {

		img {
			height: 130px;
			width: auto;
		}
	}

	.title {
		padding: 15px;

		h2 {
			line-height: 1.5em;
			height: 3em;
			overflow: hidden;
		}
	}

	.description {

	}
}

.profile-container {

	.profile-content {

		.fa {
			font-size: 24px;
		}
	}
	table {
		
		height: 500px;
		td {
			vertical-align: middle;
		}
	}

	.team-photo {
		background-size: cover;
		background-position: center center;
		height: 500px;

		@media #{$tablet} {
			max-width: 360px;
			margin: 15px auto 0;
		}
	}
}

.single-team .gallery-container {
	background: #f7f7f7;
	padding: 45px 0;
	margin: 0;

	h1 {
		margin-bottom: 45px;
	}
}

.page-id-1903 .gallery-container {
	background: #f7f7f7;
	padding: 45px 0;
	margin: 0;

	h1 {
		margin-bottom: 45px;
	}
}

.page-id-68 .gallery-container {
	background: #f7f7f7;
	padding: 45px 0;
	margin: 0;

	h1 {
		margin-bottom: 45px;
	}
}

.map-container {
	padding-top: 33vh;
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0;
		height: 100%;
		width: 100%;
	}
}

.single-info {
	text-align: center;
	padding: 60px 0;
	.icon {
		background-color: #eee;
		border-radius: 100px;
		height: 100px;
		width: 100px;
		margin-bottom: 20px;
		display: inline-block;

		&:hover {
			background: $primary;

			.fa {
				color: white;
			}
		}

		.fa {
			color: $primary;
			font-size: 45px;
			line-height: 30px;
			margin-top: 35px;
		}
	}

	.title {

	}

	.info {
		margin-top: 20px;
		font-size: 22px;
	}
}

.contact-form-container {
	background: #f7f7f7;
	padding: 60px 0 40px;

	@media #{$tablet} {
		text-align: left !important;

		* {
			text-align: left !important;
		}
	}
}

.process-container {
	padding: 60px 0;

	.col-md-4:nth-of-type(3n+4) {
		clear:both;
	}
}

.process-single {
	text-align: center;

	.image {
		display: inline-block;
		border-radius: 100px;
		border: 1px solid #ccc;
		padding: 40px;
		position: relative;

		img {
			height: 120px;
			width: auto;
		}

		.count {
			position: absolute;
			bottom: 10px;
			right: 10px;
			height: 40px;
			width: 40px;
			line-height: 40px;
			font-weight: 700;
			color: #fff;
			text-align: center;
			background: $primary;
			border-radius: 50px;
			border: 1px solid darken($primary, 20%)
		}
	}
}

.testimonials-container {
	padding: 60px 0;
}

.test-single {
	background-image: url('../../assets/images/blockquote.png');
	background-position: 0 80px;
	background-repeat: no-repeat;

	h2.underline {
		text-align: left;

		&:after {
			left: 0;
			margin: 0;
		}
	}

	blockquote {
		background: url('../../assets/images/textline.png');
		font-size: 17px;
		line-height: 31px;
		border: 0;
		margin: 40px;
		padding: 0;
	}
}

.page-id-2083 {


	.features-services {
		text-align: center;

		article {
			width: 30%;
			display: inline-block;
			margin-left: 26px;

			@media #{$tablet} {
				width: 45%;
			}

			@media #{$mobile} {
				width: 100%;
				margin: 0;
			}
		}
	}
}

.modal.in {
	display: block;
}

.modal {
	display: none;
}

.modal-dialog {
	width: 100%;
}

.modal-content {
	top: 92px;
	background: darken($primary, 5%);
	border-radius: 0;
	width: 100%;
	box-shadow: 0 0 0 transparent;
	border: 0;

	.modal-header {
		border: 0;
	}

	.modal-footer {
		border: 0;
	}

	input[type="text"] {
		padding: 20px 20px 21px;
		border: 0;
		width: calc(100% - 125px);
	}

	input[type="submit"] {
		padding: 20px;
		margin-top: -2px;
		width: 120px;
	}

	.btn {
		background: white;
		color: $primary;
	}
}

.additional-awards {
	padding: 60px 0 0;
	text-align: center;

	ul {
		margin: 0;
		li {
			width: 30%;
			padding: 10px;
			font-size: 12px;

			@media #{$mobile} {
				width: 100%;
			}
		}
	} 
}