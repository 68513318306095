// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

div.gform_wrapper {
	input[type="text"],
	input[type="email"],
	select,
	textarea {
		border: 1px solid #ddd;
		padding: 10px 15px !important;
		line-height: 20px;
		border-radius: 0;
		display: block;
	}

	label {
		font-size: 14px !important;
		font-weight: 400 !important;
		margin: 0 !important;
		line-height: 20px !important;
	}

	input[type="submit"] {
		@extend .btn;
		@extend .btn-primary;
	}
}