.nav-links {
	padding: 30px 0;
	@extend .clearfix;

	.nav-previous {
		@extend .btn;
		@extend .btn-primary;
		color: #fff;
		float: left;

		a {
			color: #fff;
		}
	}

	.nav-next {
		@extend .btn;
		@extend .btn-primary;
		color: #fff;
		float: right;

		a {
			color: #fff;
		}
	}
}