.content-info {
	background: #313131;
	padding: 45px 0;

	color: #fff;

	a {
		color: #fff;
	}

	h2 {
		margin: 0 0 23px;
		font-size: 14px;
		color: #888;
	}

	hr {
		border-color: #444;
		border-width: 1px;
		width: 100%;
	}

	.copy {
		font-size: 14px;
	}

	ul.some {
		float: right;

		@media #{$mobile} {
			float: none;
		}

		a {
			color: $primary;
		}
	}
}