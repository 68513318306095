.banner {
	background: #fff;
	height: 90px;
	overflow: hidden;
	position: sticky;
	top: 0;
	z-index: 999;

	@media #{$mobile} {
		text-align: center;
	}

	.brand {
		line-height: 80px;
		display: block;
		margin: 5px 0;

		img {
			height: 70px;
			width: auto;
			display: inline-block;
		}
	}
}

.menu-container	{
	margin-right: -15px;

	@media #{$mobile} {
		display: none;
	}

	* {
		float: none;
		display: inline-block;
	}

	.navbar-collapse {
		margin: 0;
		padding: 0;
	}

	.navbar-nav {
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 0;

			&.menu-item-75 {
				background: $primary;
				
				a {
					color: #fff;
				}
			}

			a {
				line-height: 30px;
				padding: 30px 20px;
				color: #72747c;

				@media #{$tablet} {
					padding: 30px 12px;
				}

				&:hover {
					background: lighten($primary, 10%);
					text-decoration: none;
					color: #fff;
				}
			}
		}
	}
}