.btn {
	padding: 15px 30px;
	font-size: 16px;
	border: 0;
	border-radius: 0;

	&-primary {
		background: $primary;
		color: #fff;

		&:hover {
			background: darken($primary, 10%);
			text-decoration: none;
			color: #fff;
		}
	}
	&.white {
		background: #fff;
		color: #333;
	}
}